import React, { Component } from "react";
import { Link, useStaticQuery, graphql } from "gatsby";

import styled from "styled-components";
import { Container, Row, Col } from "react-grid-system";
import Contribute from "./Contribute";
import Carbon from "./Carbon";
// import Leaderboard from "./Leaderboard";

import { TwitterTimelineEmbed } from "react-twitter-embed";

const Card = styled.div`
  max-height: 400px;
  border-radius: 4px;
  margin: 20px;
  background: #fcfcfc;
  -moz-box-shadow: 0 0.2rem 3rem rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.08);
  box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.08);
  transition: all 200ms ease;

  &:active,
  &:focus,
  &:hover {
    -moz-box-shadow: 0 0.2rem 3rem rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.2);
    box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.2);
  }
`;

const Title = styled.h1`
  margin-top: 15px;
  margin-bottom: 2px;
  text-align: center;
  font-size: 1.6em;
  color: #191919;
  @media only screen and (max-width: 1024px) {
    margin-top: 5px;
  }
`;

const TitleHeader = styled.h2`
  text-align: center;
  padding: 16px 0px;
`;

const Site = styled.div`
  max-width: 100%;
  margin: auto;
`;
const List = styled.div`
  max-width: 95%;
  margin: auto;
`;

const Middle = styled.div`
  left: 0;
  text-align: center;
  right: 0;
  margin: 0 auto;
  padding: 16px;
  font-size: 16px;
  letter-spacing: -0.5px;
  ${"" /* text-align: center; */} bottom: 30px;
  color: #545659;
`;

const Img = styled.div`
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 175px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;

function Project(props) {
  return (
    <Link to={props.url}>
      <Card className="project">
        <Img src={props.img} />
        <h1 className="text-center text-2xl tracking-tight leading-none font-bold text-gray-800 mt-4">{props.title}</h1>
        <Middle>{props.quote}</Middle>
      </Card>
    </Link>
  );
}

const Projects = () => {
  const data = useStaticQuery(communityQuery).allMaker.edges;
  data.sort((a, b) => (new Date(a.node.date) > new Date(b.node.date) ? -1 : 1));
  const firstRow = data.slice(0, 3);
  const secondRow = data.slice(3, 7);
  return (
    <Site>
      <List>
        <Container fluid>
          <Row>
            {firstRow.map((item, index) => (
              <Col lg={12} md={6} xs={12} key={index}>
                <Project
                  url={`/makers/${item.node.id}`}
                  img={item.node.image}
                  title={item.node.title}
                  author={item.node.user}
                  quote={item.node.quote}
                />
              </Col>
            ))}
          </Row>
          <Col>
            <br />
            <br />
            <div className="tc-flex">
              <Carbon carbonUrl="https://cdn.carbonads.com/carbon.js?serve=CK7D6K7W&placement=enlightnyc" />
            </div>{" "}
            <br />
          </Col>
          {/* <TitleHeader className="playfair">Top Makers</TitleHeader> */}
          {/* <Leaderboard /> */}
          <br />
          {/* <p className="tc">
            <a href="/contribute">Contribute</a> a project to climb up the
            charts!
          </p> */}
          <br />
          <Row>
            {secondRow.map((item, index) => (
              <Col lg={12} md={6} xs={12} key={index}>
                <Project
                  url={`/makers/${item.node.id}`}
                  img={item.node.image}
                  title={item.node.title}
                  author={item.node.user}
                  quote={item.node.quote}
                />
              </Col>
            ))}
          </Row>{" "}
          <p className="tc">
            {" "}
            Interested in sharing your experiences as a maker? Email
            <a href="mailto:team@enlight.nyc"> team@enlight.nyc </a>
          </p>
        </Container>
      </List>
      <br />
      <Col>
        <TwitterTimelineEmbed
          sourceType="profile"
          screenName="tryenlight"
          options={{ height: 800 }}
        />{" "}
      </Col>
    </Site>
  );
};

export default Projects;

export const communityQuery = graphql`
  query CommunityQuery {
    allMaker(filter: { published: { eq: true } }) {
      edges {
        node {
          body
          date
          id
          image
          quote
          title
          user
        }
      }
    }
  }
`;
