import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const ContributeCard = styled.div`
  color: #ffffff;
  background: linear-gradient(to right, #438cee, #2f80ed);
  moz-box-shadow: 0 0 15px #ffffff;
  -webkit-box-shadow: 0 0 15px #ffffff;
  box-shadow: 0 0 15px #ffffff;
  transition: all 200ms ease;
  height: 320px;

  h2,
  h3 {
    color: #ffffff;
    padding: 16px 32px;
    text-align: center;
  }

  p {
    padding: 32px 16px;
    font-size: 18px;
    color: #ffffff;
  }

  &:hover {
    moz-box-shadow: 0 0 15px #e4e4e4;
    -webkit-box-shadow: 0 0 15px #e4e4e4;
    box-shadow: 0 0 15px #e4e4e4;
    opacity: 0.9;
  }
`;

const Contribute = () => (
  <Link to="/contribute">
    <ContributeCard>
      <h2>Contribute a project.</h2>
      <p>
        {" "}
        Share your knowledge with 50,000+ developers by contributing your
        programming projects.
      </p>
    </ContributeCard>
  </Link>
);

export default Contribute;
