import React, { Component } from "react";
import styled from "styled-components";

import Projects from "../components/Projects";
import Community from "../components/Community";

import withAuthorization from "../components/Session/withAuthorization";

import { Row, Col } from "react-grid-system";

const TitleHeader = styled.h2`
  text-align: center;
  padding: 16px 0;
`;

class HomePage extends Component {
  render() {
    return (
      <div>
        {/* <h1>Welcome, X</h1> */}

        <Projects />
        {/* <Col lg={3} md={12} xs={12}>
            <h1 className="text-center text-3xl tracking-tight leading-none font-extrabold text-gray-800 mt-8">
              Featured Makers
            </h1>
            <br />
            <Community />
            <br />
          </Col> */}
      </div>
    );
  }
}

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(HomePage);
