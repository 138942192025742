import React, { useState, useEffect, Component } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

import { Container, Row, Col } from "react-grid-system";
import Filters from "./Filters";

import { Card, Left, Right, Img } from "components/Elements/Card";

function Project(props) {
  return (
    <Link to={props.url}>
      <Card className="project text-center">
        <Img src={props.img} />
        <h1 className="text-2xl leading-snug font-bold text-gray-800 mt-6">
          {props.title}
        </h1>
        <Left className="text-xs tracking-tight rounded-lg py-2 px-4 bg-gray-200 text-gray-800 mr-1 font-bold">
          {" "}
          {props.language}
        </Left>
        <Right className="uppercase text-xs tracking-wide rounded-lg py-2 px-4 bg-gray-200 text-gray-800 mr-1 font-bold">
          {" "}
          {props.difficulty}
        </Right>
      </Card>
    </Link>
  );
}

const Projects = () => {
  let data = useStaticQuery(landingPageQuery).allTutorial.edges;
  const [projectData, setProjectData] = useState(data);

  // state lifted from Filters
  const [type, setType] = useState("");
  const [language, setLanguage] = useState("");
  const [difficulty, setDifficulty] = useState("");

  useEffect(() => {
    let filtered = data;
    if (type !== "") {
      filtered = filtered.filter(item => item.node.category === type);
    }
    if (language !== "") {
      filtered = filtered.filter(item => item.node.language === language);
    }
    if (difficulty !== "") {
      filtered = filtered.filter(item => item.node.difficulty === difficulty);
    }
    setProjectData(filtered);
  }, [type, language, difficulty]);

  return (
    <div className="mx-auto max-w-screen-xl py-8">
      <h1 className="text-5xl font-extrabold text-center text-gray-800 tracking-tight">
        Projects
      </h1>
      <h2 className="text-2xl text-center text-gray-700 tracking-tight mb-4 px-1">
        Discover your next Enlight project.
      </h2>
      <Container fluid>
        <Filters
          data={data}
          type={type}
          setType={setType}
          language={language}
          setLanguage={setLanguage}
          difficulty={difficulty}
          setDifficulty={setDifficulty}
        />
        <br />
        <Row>
          {projectData
            .sort((a, b) => b.node.projectId - a.node.projectId)
            .map(item => (
              <Col lg={4} md={6} xs={12} key={item.node.id}>
                <Project
                  url={`/projects/${item.node.id}`}
                  img={item.node.img}
                  title={item.node.title}
                  author={item.node.author}
                  difficulty={item.node.difficulty}
                  language={item.node.language}
                />
              </Col>
            ))}
          {/* <Col lg={4} md={6} xs={12}>
            <Link to="/contribute">
              <Card>
                <Contribute />
              </Card>
            </Link>
          </Col> */}
        </Row>
      </Container>
    </div>
  );
};

export default Projects;

export const landingPageQuery = graphql`
  query LandingPageQuery {
    allTutorial(
      filter: { published: { eq: true }, category: { ne: "Onboarding" } }
      sort: { fields: projectId, order: DESC }
    ) {
      edges {
        node {
          difficulty
          language
          category
          title
          author
          id
          projectId
          img
          date
        }
      }
    }
  }
`;
