import React, { useState, useEffect } from "react";

function Filters({
  data,
  type,
  setType,
  language,
  setLanguage,
  difficulty,
  setDifficulty
}) {
  // 3 state variables to represent choices
  const [typeArr, setTypeArr] = useState([]);
  const [langArr, setLangArr] = useState([]);
  const [diffArr, setDiffArr] = useState([]);

  // populated from mapping through graphql
  useEffect(() => {
    setTypeArr([
      ...new Set(
        data
          .filter(a => a.node.category != "Algorithms")
          .sort((a, b) => a.node.category.localeCompare(b.node.category))
          .map(item => item.node.category)
      )
    ]);
    setLangArr([
      ...new Set(
        data
          .sort((a, b) => a.node.language.localeCompare(b.node.language))
          .map(item => item.node.language)
      )
    ]);
    setDiffArr([...new Set(data.map(item => item.node.difficulty))]);
  }, []);

  return (
    <div className="mx-auto sm:flex justify-center">
      <div className="px-6">
        <p className="text-center font-bold text-gray-800 mb-2">Category</p>
        <select
          className="sm:w-48 block w-full bg-gray-200 border border-gray-300 text-gray-700 p-1 px-2 rounded leading-tight focus:outline-none focus:bg-gray-100 focus:border-gray-500"
          value={type}
          onChange={e => setType(e.currentTarget.value)}
        >
          <option></option>
          {typeArr.map((category, i) => (
            <option key={i}>{category}</option>
          ))}
        </select>
      </div>
      <div className="px-8">
        <p className="text-center font-bold text-gray-800 mb-2">Language</p>
        <select
          className="sm:w-48 block w-full bg-gray-200 border border-gray-300 text-gray-700 p-1 px-2 rounded leading-tight focus:outline-none focus:bg-gray-100 focus:border-gray-500"
          value={language}
          onChange={e => setLanguage(e.currentTarget.value)}
        >
          <option></option>
          {langArr.map((lang, i) => (
            <option key={i}>{lang}</option>
          ))}
        </select>
      </div>
      <div className="px-8">
        <p className="text-center font-bold text-gray-800 mb-2">Difficulty</p>
        <select
          className="sm:w-48 flex-1 block w-full bg-gray-200 border border-gray-300 text-gray-700 p-1 px-2 rounded leading-tight focus:outline-none focus:bg-gray-100 focus:border-gray-500"
          value={difficulty}
          onChange={e => setDifficulty(e.currentTarget.value)}
        >
          <option></option>
          {diffArr.map((diff, i) => (
            <option key={i}>{diff}</option>
          ))}
        </select>
      </div>
    </div>
  );
}

export default Filters;
